.laqvmk3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:12px 0;}.laqvmk3:not(:last-of-type){border-bottom:1px solid #e2e8f1;}
.t1srlcp3{width:62px;height:70px;margin-right:12px;background-image:url('/assets/icons/lecture/anue-calender.svg');background-repeat:no-repeat;position:relative;}
.t1rphj8m{position:absolute;top:0;left:0;width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.dbz5zu2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:9px;}
.y1xo5ejx{font-size:12px;line-height:1;color:#000;}
.mk0uqu7{font-size:12px;line-height:1;font-weight:500;color:#000;}
.d14lhah7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:6px;}
.d1qy19zf{font-size:18px;font-weight:500;line-height:1;color:#000;margin-right:3px;}
.w1fly6t2{font-size:12px;line-height:1;color:#000;}
.djcaiae{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:12px;line-height:1;color:#848d97;margin-top:3px;}
.tyyjvbt{-webkit-flex:1;-ms-flex:1;flex:1;display:inline;font-size:16px;line-height:1.6;color:#383838;text-align:left;}.tyyjvbt:hover{color:#e03f19;}
.h1xd7q4s{padding:2px 4px;font-size:12px;font-weight:500;line-height:12px;color:#e03f19;background-color:#feede9;text-align:center;border-radius:4px;margin-right:4px;}
